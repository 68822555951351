import { useStaticQuery, graphql } from 'gatsby'

const useAllMediaImage = () => {
    const data = useStaticQuery(graphql`
        query {
            allMediaImage {
                edges {
                    node {
                      	field_media_image {
                            alt
                            title
                            width
                            height
                        }
                      	relationships {
                            field_media_image {
                                drupal_id
                            }
                        }
                    }
                }
            }
        }
    `)

    return data
}

export default useAllMediaImage